import Link from 'next/link';
import {
  Box,
  Button,
  Card,
  Flex,
  Section,
  Heading,
  Inset,
  Separator,
  Text,
} from '@radix-ui/themes';

import type { RPCError, RPCOutputs } from '@/types/rpc';
import { api } from '@/data/client';

import LoadingView from '@/views/components/Loading';
import ErrorView from '@/views/components/Error';
import GeometryMap from '@/views/components/GeometryMap';
import ProviderStatusLabel from '@/views/components/ProviderStatusLabel';

export function PreviewProviderLoading() {
  return (
    <Card>
      <LoadingView />
    </Card>
  );
}

export default function PreviewProvider({
  loading,
  providerId,
  providerData,
}: {
  loading?: boolean;
  providerId: string;
  providerData?: RPCOutputs['provider']['getProvider'];
}) {
  const { data, isLoading, isError, error } = api.provider.getProvider.useQuery(
    {
      providerId,
    },
    {
      enabled: !loading && !!providerId,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: !!providerData === false,
      initialData: providerData,
    },
  );

  if (!data || !data.provider) {
    if (isLoading) {
      return <PreviewProviderLoading />;
    }

    if (isError) {
      return <ErrorView error={error as RPCError} />;
    }

    return null;
  }

  return (
    <Card>
      <Inset clip="padding-box" side="top" pb="current">
        <figure>
          <GeometryMap
            coordinates={data.provider.coordinates}
            geometry={data.provider.coverage.geometry as GeoJSON.FeatureCollection}
            bounds={{
              sw: [data.bounds[0], data.bounds[1]],
              ne: [data.bounds[2], data.bounds[3]],
            }}
          />
          <figcaption className="sr-only">
            map of provider coverage area
          </figcaption>
        </figure>
        <Separator size="4" />
      </Inset>

      <div className="grid gap-10 p-6">
        <div className="grid grid-flow-row gap-6 md:grid-flow-col">
          <div className="flex flex-col items-center justify-center gap-4">
            <Heading as="h2">{data.provider.organization.name}</Heading>
            <ProviderStatusLabel
              status={data.provider.status}
              loading={isLoading}
            />
          </div>
        </div>

        {/* <Flex>
          <Flex direction="column" justify="center" align="center">
            <Text as="span" size="9" weight="bold" color="grass">
              4
            </Text>
            <Text as="span" size="4" weight="medium" color="gray">
              routes this week
            </Text>
          </Flex>
        </Flex> */}

        <footer className="flex w-full justify-center">
          <Button variant="soft" size="2">
            <Link href={`/app/providers/${data.provider.id}`}>Dashboard</Link>
          </Button>
        </footer>
      </div>
    </Card>
  );
}
