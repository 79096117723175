import { forwardRef } from 'react';

import {
  GeometryLayer,
  GeometryLayerProps,
  Map,
  type MapProps,
  type MapRef,
} from '@/components/maps';

import type { Coordinates } from '@/data';

export type { MapRef };

export const GeometryMap = forwardRef<
  MapRef,
  MapProps & {
    coordinates?: Pick<Coordinates, 'latitude' | 'longitude'>;
    geometry: GeometryLayerProps['data'];
    bounds?: {
      sw: [number, number];
      ne: [number, number];
    };
    source?: GeometryLayerProps['source'];
    layer?: GeometryLayerProps['layer'];
    size?: 'small' | 'medium' | 'large' | 'auto';
  }
>(function geometryMap(
  { coordinates, geometry, bounds, source, layer, size = 'small', ...props },
  ref,
) {
  return (
    <Map
      ref={ref}
      {...props}
      reuseMaps
      latitude={coordinates?.latitude}
      longitude={coordinates?.longitude}
      bounds={bounds}
      className={
        size === 'small'
          ? 'h-[300px] min-h-[300px]'
          : size === 'medium'
            ? 'h-[400px] min-h-[400px]'
            : size === 'large'
              ? 'h-[500px] min-h-[500px]'
              : 'h-[300px] min-h-[300px]'
      }
    >
      {geometry && (
        <GeometryLayer source={source} layer={layer} data={geometry} />
      )}
    </Map>
  );
});

export default GeometryMap;
