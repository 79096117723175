'use client';

import { useMemo } from 'react';
import Link from 'next/link';
import {
  Box,
  Button,
  Card,
  Container,
  Flex,
  Grid,
  Heading,
  Section,
  Skeleton,
  Text,
} from '@radix-ui/themes';

import type { RPCError, RPCOutputs } from '@/types/rpc';
import { api } from '@/data/client';

import ErrorView from '@/views/components/Error';
import PreviewProperty from '@/views/widgets/PreviewProperty';
import PreviewProvider from '@/views/widgets/PreviewProvider';

export const i18n = {
  en: {
    title: 'Welcome to Hedge',
    customer: 'Customer',
    provider: 'Provider',
    households: 'Households',
    moreHouseholds: '+{count} more households',
    actions: {
      viewProperties: 'see properties',
    },
  },
  links: {
    customer: '/app/properties/add',
    provider: '/app/providers/join',
  },
};

export function ApplicationDashboardSkeleton() {
  return <HouseholdsOverview loading />;
}

export function ProviderOverview({
  loading = false,
  teams = [],
}: {
  loading?: boolean;
  teams: NonNullable<RPCOutputs['user']['me']>['memberships'];
}) {
  return (
    <Flex direction="column" gap="6">
      <header>
        <Skeleton loading={loading}>
          <Heading as="h2">{i18n.en.provider}</Heading>
        </Skeleton>
      </header>

      <Box>
        <ul className="grid w-full grid-flow-row gap-8 p-0">
          {teams.map((team, index) => (
            <li key={index}>
              <PreviewProvider
                loading={loading}
                providerId={team.providerId!}
              />
            </li>
          ))}
        </ul>
      </Box>
    </Flex>
  );
}

export function HouseholdsOverview({
  loading = false,
  households = [],
}: {
  loading?: boolean;
  households?: NonNullable<RPCOutputs['user']['me']>['memberships'];
}) {
  const primaryHousehold = households[0];
  return (
    <Flex direction="column" gap="6">
      <header>
        <Flex justify="between" align="center">
          <Skeleton loading={loading}>
            <Heading as="h2">{i18n.en.households}</Heading>
          </Skeleton>
          <Skeleton loading={loading}>
            <Button variant="soft" size="3" asChild>
              <Link href="/app/properties">
                <Text size="3">{i18n.en.actions.viewProperties}</Text>
              </Link>
            </Button>
          </Skeleton>
        </Flex>
      </header>

      <Box>
        <PreviewProperty
          loading={loading}
          propertyId={primaryHousehold?.propertyId!}
        />

        {households.length > 1 && (
          <Flex justify="center" mt="3">
            <Text size="3" className="mt-4">
              {i18n.en.moreHouseholds.replace(
                '{count}',
                String(households.length - 1),
              )}
            </Text>
          </Flex>
        )}
      </Box>
    </Flex>
  );
}

export function Onboarding() {
  return (
    <section>
      <header className="my-4 mb-8">
        <Heading as="h1">{i18n.en.title}</Heading>
      </header>

      <Grid gap="4" columns={{ initial: '1', xs: '2' }}>
        <Link
          href={i18n.links.customer}
          className="rounded-lg outline-offset-2"
        >
          <Card className="h-full">
            <Text size="4" weight="bold">
              {i18n.en.customer}
            </Text>
          </Card>
        </Link>

        <Link
          href={i18n.links.provider}
          className="rounded-lg outline-offset-2"
        >
          <Card className="h-full">
            <Text size="4" weight="bold">
              {i18n.en.provider}
            </Text>
          </Card>
        </Link>
      </Grid>
    </section>
  );
}

export default function ApplicationDashboard(props: {
  user: RPCOutputs['user']['me'];
}) {
  const { data, isLoading, isError, error } = api.user.me.useQuery(undefined, {
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    // TODO: fix this
    // refetchOnMount: !!props.user === false,
    initialData: props.user,
  });

  const { memberships, households, teams } = useMemo(() => {
    const memberships = (data?.memberships || []).sort((a, b) => {
      if (a.isPrimary) {
        return -1;
      }

      if (b.isPrimary) {
        return 1;
      }

      return 0;
    });

    const households = memberships.filter((m) => m.type === 'HOUSEHOLD');
    const teams = memberships.filter((m) => m.type === 'PROVIDER');

    return {
      memberships,
      households,
      teams,
    };
  }, [data?.memberships]);

  if (!data) {
    if (isLoading) {
      return <ApplicationDashboardSkeleton />;
    }

    if (isError) {
      return <ErrorView error={error as RPCError} />;
    }

    return null;
  }

  return (
    <div>
      {memberships.length === 0 ? (
        <Onboarding />
      ) : households.length > 0 && teams.length > 0 ? (
        <ul className="mx-auto grid w-full grid-flow-row gap-12 p-0">
          <li>
            <HouseholdsOverview households={households} />
          </li>
          <li>
            <ProviderOverview teams={teams} />
          </li>
        </ul>
      ) : (
        <div className="mx-auto grid w-full grid-flow-row gap-12 p-0">
          {teams.length > 0 && <ProviderOverview teams={teams} />}
          {households.length > 0 && (
            <HouseholdsOverview households={households} />
          )}
        </div>
      )}
    </div>
  );
}
