import type { HTMLAttributes, PropsWithChildren } from 'react';
import { Card, type CardProps } from '@tremor/react';

const i18n = {
  en: {
    loading: 'Loading...',
  },
};

export default function LoadingView() {
  return <div>{i18n.en.loading}</div>;
}

export function LoadingBlock({
  children,
  className,
  ...props
}: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) {
  return (
    <div
      className={`rounded-md bg-sage-3 dark:bg-sageDark-8 ${className}`}
      {...props}
    >
      {children}
    </div>
  );
}

export function LoadingCard({
  children,
  className,
  ...props
}: PropsWithChildren<CardProps>) {
  return (
    <Card className={`h-full ${className}`} {...props}>
      {children}
    </Card>
  );
}
