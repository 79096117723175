import { Skeleton, Text } from '@radix-ui/themes';

import type { ProviderStatus } from '@/data';

export default function ProviderStatusLabel({
  loading = false,
  status = 'PENDING',
}: {
  loading?: boolean;
  status: ProviderStatus;
}) {
  return (
    <Text color="grass">
      <Skeleton loading={loading}>{status.toLowerCase()}</Skeleton>
    </Text>
  );
}
